// // Background Mixins //
// @each $color, $value in $theme-gradient-colors {
// 	.bg-gradient-#{$color} {
// 		background: theme-gradient-color($color);
// 	}
// }
// Background Mixins //
@each $color, $value in $theme-gradient-colors {
    .bg-gradient-#{$color} {
        color: #fff;
        background: theme-gradient-color($color);
        @if $color == success {
            box-shadow: 0 -2px 0 0 inset #00ca76 !important;
            &:active {
                box-shadow: 0 0 0 0 inset #00ca76 !important;
            }
        } @else if $color == info {
            box-shadow: 0 -2px 0 0 inset #0061ca !important;
            &:active {
                box-shadow: 0 0 0 0 inset #0061ca !important;
            }
        } @else if $color == danger {
            box-shadow: 0 -2px 0 0 inset #fc5884 !important;
            &:active {
                box-shadow: 0 0 0 0 inset #fc5884 !important;
            }
        }
    }
}