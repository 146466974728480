.docimg {
  width: 30%;
  height: 30%;
  border-radius: 50%;
}
.profile {
  
  padding: 1rem 1rem 1rem 0;
 // box-shadow:20px 10px 10px 20px #b0b8d617, 10px 10px 15px 0px #b0b8d6;
}
.profile2{
      // width: 55%;
      // display: flex;

}
.profileor{
    
      
}
.tapover {
  max-width: 100%;
  overflow: auto;
}



.ph {
  padding: 10px;
}
.editbtn {
  width: 35%;
  background-color: #0242ff;
  color: white;
  border-radius: 7px;
  padding: 5px;
}
.car1 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
