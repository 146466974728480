
.floating {
  // margin-bottom: 2rem;
  background-color: #f0f0f0;
  transition: background-color 0.2s ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.floating:hover,
.floating:focus-within {
  background-color: #e9e9e9;
}

.floating__input {
  width: 100%;
  background: #f0f0f0;
  padding: 1.6rem 1rem 0.4rem;
  font-size: 13px;
  border: none;
  transition: border-color 0.2s ease;
  caret-color: #6200ee;
}

.floating:hover .floating__input {
  border-color: rgba(0, 0, 0, 0.62);
}

.floating__input::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__label {
  display: block;
  position: relative;
  font-size: 13px;
  max-height: 0;
  font-weight: 400;
  pointer-events: none;
}

.floating__label::before {
  color: #222;
  content: attr(data-content) attr(data-ref);
  display: inline-block;
  filter: blur(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: left top;
  transition: transform 0.2s ease;
  left: 1rem;
  position: relative;
}

.floating__label::after {
  bottom: 1rem;
  content: "";
  height: 0.1rem;
  position: absolute;
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.3s ease;
  opacity: 0;
  left: 0;
  top: 100%;
  margin-top: -0.1rem;
  transform: scale3d(0, 1, 1);
  width: 100%;
  background: #f0f0f0;
}

.floating__input:focus + .floating__label::after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.floating__input:-moz-placeholder-shown + .floating__label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.floating__input:-ms-input-placeholder + .floating__label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.floating__input:placeholder-shown + .floating__label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.floating__label::before,
.floating__input:focus + .floating__label::before {
  transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
}

.floating__input:focus + .floating__label::before {
  color: #222;
}

.hidden--visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}